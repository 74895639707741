// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_API_URL}login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: `${process.env.REACT_APP_API_URL}logout`,
  forgotpasswordEndPoint: `${process.env.REACT_APP_API_URL}forgot-password`,
  resetpasswordEndpoint: `${process.env.REACT_APP_API_URL}reset-password`,
  activateEndpoint: `${process.env.REACT_APP_API_URL}activate`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token'
}
